import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { patientVitalsColor } from "../../utils/data/PatientVitalsColor";
import { CustomRow } from "../CustomRow";
//import { DeviceAlarm } from "./DeviceAlarm";
import { useTranslation } from "react-i18next";

// Interface for parameter values or subparameters
export interface ParameterValue {
  value: number | string;
  text?: string;
  unit?: string;
}

// Interface for parameters that might have subparameters
export interface ParameterBlock {
  param_name: string;
  value?: ParameterValue;
  active_alarm: boolean;
  subparameters?: Record<string, ParameterValue>;
}

interface Props {
  parameter: ParameterBlock;
  connectStatus: string;
  compact?: boolean;
}

export const ParameterDisplay = ({ parameter, connectStatus, compact = false }: Props) => {
  const paramsBP = ["IBP1", "IBP2", "NIBP"];
  const [color, setColor] = useState<string>("#000");
  const { t } = useTranslation();
  const [colors] = useState(patientVitalsColor);

  //console.log ("DIsplay parameter: ", parameter);
  useEffect(() => {
    if (parameter) {
      for (const [key, value] of Object.entries(colors)) {
        if (key === parameter.param_name) setColor(value);
      }
    }
  }, [colors, parameter.param_name, parameter]);

  const isConnected = connectStatus === "connected";
  const renderValue = (paramValue: ParameterValue | undefined, fallback = "--") =>
    paramValue?.value && isConnected ? paramValue.value : fallback;


  /*
   * BP
   */

  const renderBPParam = () => {
    const systole  = renderValue(parameter.subparameters?.systole);
    const diastole = renderValue(parameter.subparameters?.diastole);
    const map      = renderValue(parameter.subparameters?.map);
    const parmName = parameter.param_name;
    const parmUnit = parameter.subparameters?.systole?.unit;
  
    return (
      <Grid container spacing={1}>
        {/* First line: parmName, blank, systeole/diastole valuea */}
        <Grid item xs={3}>
          <Typography variant="h5" color="white">{parmName}</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography style={{ textAlign: 'right', marginTop: "6px" }} color="white">{parmUnit}</Typography>
        </Grid>

        <Grid item xs={6} >
          <Typography variant="h5" style={{ textAlign: 'right' }} color="white">{`${systole} / ${diastole}`}</Typography>
        </Grid>
  

        {/* Second line: blank, Map label, Map value */}
        <Grid item xs={3}></Grid>
        <Grid item xs={3} >
          <Typography style={{ textAlign: 'right', marginTop: "6px" }} color="white">Map</Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right', marginTop: "6px" }}>
        <Typography color="white">{map}</Typography>
        </Grid>
      </Grid>
    );
  };



  /*
   * Resp
   */
  const renderRespParam = () => {

    const rr = parameter.subparameters?.rr?.value;
    const co2 = parameter.subparameters?.co2?.value;
    const parmName = parameter.param_name;
    const co2Unit = parameter.subparameters?.co2?.unit;
    //const rrUnit = parameter.subparameters?.rr?.unit;
  
    return (
      <Grid container spacing={1} >

        {/* First line: parmName, CO2 label, CO2 value */}
        <Grid item xs={3}>
          <Typography variant="h5" color="white">{parmName}</Typography>
        </Grid>

        <Grid item xs={3} style={{ textAlign: 'left' }}>
          <Typography variant="h5" color="white">RR</Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography style={{ textAlign: 'right', marginTop: "12px" }} color="white">r/min</Typography>
        </Grid>

        <Grid item xs={3} style={{ textAlign: 'right' }}>
          <Typography variant="h4" color="white">{rr}</Typography>
        </Grid>
  


        {/* Second line: blank, RR label, RR value */}
        <Grid item xs={3}></Grid>

        <Grid item xs={3} style={{ textAlign: 'left' }}>
          <Typography variant="h5" color="white">CO<sub>2</sub></Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography style={{ textAlign: 'right', marginTop: "12px" }} color="white">{co2Unit}</Typography>
        </Grid>



        <Grid item xs={3} style={{ textAlign: 'right' }}>
          <Typography variant="h4"  color="white">{co2}</Typography>
        </Grid>
      </Grid>
    );
  };
  

  /*
   * Temp
   */
  const renderTempParam = () => {
    let temp1 = parameter.subparameters?.t1?.value;
    let temp2 = parameter.subparameters?.t2?.value && false;
    const parmName = parameter.param_name;
    const parmUnit = parameter.subparameters?.t1?.unit;
  
    // If temp1 is undefined, use temp2 instead and set temp2 to undefined
    if (!temp1 && temp2) {
      temp1 = temp2;
      temp2 = undefined; // Reset temp2 so it's not shown
    }
  
    return (
      <Grid container spacing={1}>

        {/* First line: parmName, unit and Temp value */}
        <Grid item xs={4}>
          <Typography variant="h5" color="white">{parmName}</Typography>
        </Grid>
  
        <Grid item xs={4}>
          <Typography   style={{ textAlign: 'left', marginTop: "6px" }} color="white">{"°" + parmUnit}</Typography>
        </Grid>

        <Grid item xs={4} style={{ textAlign: 'right' }}>
          {/* If temp2 is undefined, show temp1 without "Temp1" label */}
          <Typography variant="h4" color="white">{temp1}</Typography>
        </Grid>
  
        {/* Second line: blank, Temp2 label, Temp2 value (only if temp2 exists) */}
        {temp2 && (
          <>
           
            <Grid item xs={4}>
              <Typography variant="h5" color="white">Temp2</Typography>
            </Grid>

            <Grid item xs={4}>
            </Grid>

            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <Typography variant="h4" color="white">{temp2}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  
  /*
   * SpO2
   */
  const renderSpO2Param = () => {
    const spValue = renderValue(parameter.subparameters?.sp);
    const perfValue = renderValue(parameter.subparameters?.perf);
    //const parmName = parameter.param_name;
    const spo2Unit = parameter.subparameters?.sp?.unit;
    const perfPresent = parameter.subparameters?.perf;

    return (
      <Grid container spacing={1}>
        {/* First line: parmName, CO2 label, CO2 value */}
        <Grid item xs={4}>
          <Typography variant="h5" color="white">SpO<sub>2</sub></Typography>
        </Grid>

        <Grid item xs={4}>
            <Typography  style={{ textAlign: 'right', marginTop: "6px" }} color="white">{spo2Unit}</Typography>
        </Grid>

        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <Typography variant="h4" color="white">{spValue}</Typography>
        </Grid>
  
        {/* Second line: blank, Perf label, Perf value */}
        { perfPresent && (
          <>
            <Grid item xs={4}>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <Typography color="white">Perf %</Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <Typography color="white">{perfValue}</Typography>
            </Grid>
          </>
        )} 
        </Grid>
    );
  };


  /*
   * HR
   */
      const renderHRParam = () => {
        const parmValue = renderValue(parameter.value);
        const parmName = parameter.param_name;
        const parmUnit = parameter.value?.unit?.toLowerCase();

        return (
          <Grid container spacing={1}>

            {/* First line: parmName, label, value */}
            <Grid item xs={4}>
              <Typography variant="h5" color="white">{parmName}</Typography>
            </Grid>
    
            <Grid item xs={3}>
                <Typography style={{ textAlign: 'left', marginTop: "6px" }} color="white">{parmUnit}</Typography>
            </Grid>
    
            <Grid item xs={5} style={{ textAlign: 'right' }}>
              <Typography variant="h4" color="white">{parmValue}</Typography>
            </Grid>
  
          </Grid>
        );
      };

  /*
   * All others
   */
  const renderDefaultParam = () => (
    <>
      <CustomRow>
        <Typography variant="h5" color="white">{t(parameter.param_name)}</Typography>
        <Typography variant="h4" color="white">{renderValue(parameter.value)}</Typography>
      </CustomRow>
    </>
  );

  const renderParameterContent = () => {
    if (paramsBP.includes(parameter.param_name)) {
      return renderBPParam();
    } else if (parameter.param_name === "Resp") {
      return renderRespParam();
    } else if  (parameter.param_name === "Temp") {
      return renderTempParam();
    } else if (parameter.param_name === "SpO2") {
      return renderSpO2Param();
    } else if  (parameter.param_name === "HR") {
      return renderHRParam();
    } else {
      return renderDefaultParam();
    }
  };

  const height = "85px";
  return (
    <Grid item xs={6} className={parameter.active_alarm ? "parameter-value-alarm" : "parameter-value-normal"}
            sx={{
              bgcolor: color || "common.black", // Use provided background color or default to common.black
              minHeight: height,
              border: parameter.active_alarm ? "solid 2px red" : "solid 1px white",
              padding: "3px",
          }}
          container
          direction="column"
          justifyContent="space-between"
    
    >
      <div style={{ backgroundColor: color, marginLeft: 6, marginRight: 6}}>
        {renderParameterContent()}
        {/* <DeviceAlarm parameter={parameter} connectStatus={connectStatus} />*/}
      </div>
    </Grid>
  );
};
